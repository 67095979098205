<script>
  import { Router, Route } from 'svelte-navigator';

  import RouteTransition from './components/RouteTransition.svelte';

  import Home from './pages/Home.svelte';
  import Login from './pages/Login.svelte';
  import Consent from './pages/Consent.svelte';
  import Redirect from './pages/Redirect.svelte';
</script>

<Router primary={false}>
  <div class="page">
    <main class="content">
      <Route path="/">
        <RouteTransition>
          <Home />
        </RouteTransition>
      </Route>
      <Route path="/login">
        <RouteTransition>
          <Login />
        </RouteTransition>
      </Route>
      <Route path="/consent">
        <RouteTransition>
          <Consent />
        </RouteTransition>
      </Route>
      <Route path="/redirect">
        <RouteTransition>
          <Redirect />
        </RouteTransition>
      </Route>
    </main>
  </div>
</Router>

<style>
  .page {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .page .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    flex: 1;
  }
</style>
