<script>
import Toggle from "../components/Toggle.svelte";

let showExtraData = false;

const toggleExtraData = () => {
  showExtraData = !showExtraData;
};

</script>
<div class="container">
  <div class="form-container">
    <img
      class="logo"
      src="https://www.opus-software.com.br/wp-content/uploads/2019/01/opus_logo.png"
      alt="logo"
    />
    <form action="/api/login" method="post" class="form">
      <div class="input-container">
        <input class="input-field" name="login" id="login" type="text" placeholder="Login" />
        <label class="input-label" for="login"> Login </label>
      </div>
      <div class="input-container">
        <input class="input-field" name="password" id="password" type="password" placeholder="Password" />
        <label class="input-label" for="password"> Password </label>
      </div>
      <div class="input-container">
        <input class="input-field" name="name" id="name" type="text" placeholder="Name" />
        <label class="input-label" for="name"> Name </label>
      </div>
      <div class="input-container">
        <input class="input-field" name="cpf" id="cpf" type="text" placeholder="CPF" />
        <label class="input-label" for="cpf"> CPF </label>
      </div>
      <div class="input-container">
        <input class="input-field" name="cnpj" id="cnpj" type="text" placeholder="CNPJ" />
        <label class="input-label" for="cnpj"> CNPJ </label>
      </div>
      <div name="showExtraData" on:click={toggleExtraData}>{showExtraData ? '▼' : '►'} Clique aqui para mostrar campos adicionais</div>
      <Toggle shown={showExtraData}>
        <div class="extradata-container">
          <label for="consentOwner"> Consent Owner </label>
          <textarea class="textarea-container" rows="5" name="consentOwner" />
          <label for="authExtraData"> Extra Data </label>
          <textarea class="textarea-container" rows="5" name="authExtraData" />
        </div>
      </Toggle>
      <div class="button-container">
        <button formAction="/api/login/cancel" class="cancel-button">
          Cancelar
        </button>
        <button class="login-button">Entrar</button>
      </div>
    </form>
  </div>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--bg-content-color);
    max-width: 720px;
    min-width: 360px;
    width: 80vw;
    align-items: center;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .logo {
    align-self: center;
    margin: 2rem 0;
    height: 110px;
    width: 110px;
  }

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    margin-top: 2rem;
  }

  .cancel-button {
    background-color: white;
    color: var(--primary-color);
  }

  .input-container {
    position: relative;
    padding: 20px 0 0;
    margin-top: 5px;
    width: 100%;
  }

  .input-field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid var(--primary-color);
    outline: 0;
    font-size: 1.1rem;
    color: darkslategrey;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
  }
  .input-field::placeholder {
    color: transparent;
  }

  .input-field:placeholder-shown ~ .input-label {
    font-size: 1rem;
    cursor: text;
    top: 20px;
  }
  .input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--primary-color);
  }

  .input-field:focus {
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    border-image: var(--primary-color);
    border-image-slice: 1;
  }
  .input-field:focus ~ .input-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: var(--primary-color);
    font-weight: 700;
  }

  .input-field:required,
  .input-field:invalid {
    box-shadow: none;
  }

  .textarea-container {
    width: 100%;
    border-radius: 0.5rem;
  }

  .textarea-container:focus {
    border: 1px solid var(--primary-color);
  }

  .extradata-container label {
    color: var(--primary-color);
  }
</style>
