<script>
  export let shown = false;
</script>

<div class="container {shown ? '' : 'hidden'}">
  <slot/>
</div>

<style>
  .container {
    width: 100%;
  }
  .hidden {
    display: none;
  }
</style>